<template>
    <div class="ons-campaign-container">
        <StackRouterHeaderBar :leftButton="page === 0 ? 'close' : 'back'" :leftButtonHandler="prev" />
        <div class="title" v-if="needTitle">
            <img :class="styleVariationByPage" v-lazy="title" />
        </div>
        <div class="content">
            <div class="desc" :class="styleVariationByPage" v-html="$translate(`CAMPAIGN_DESC_${page}`)" />
            <OnsCampaignEmojiInstruction v-if="emojiInstructions" :page="page" />
            <div v-else class="whole-img-container" :class="styleVariationByPage">
                <img v-lazy="wholeImg" />
            </div>
            <div class="input-container" v-if="needInput">
                <p class="f-16 m-b-6" v-html="$translate('CAMPAIGN_INPUT_SENTENCE')" />
                <p class="f-14 c-facebook f-bold m-b-20" v-html="$translate(`CAMPAIGN_INPUT_SENTENCE_${page}`)" />
                <TextareaWithX
                    :isInputMode="true"
                    v-model="dictation"
                    :placeholder="$translate(`CAMPAIGN_INPUT_SENTENCE`)"
                />
            </div>
        </div>
        <BottomButton
            :class="{ disableButtonFix }"
            service="goodbye_ons"
            :label="buttonLabel"
            @click="onClickBottomBtn"
            :disabled="disabled"
        />
    </div>
</template>

<script>
import OnsCampaignEmojiInstruction from '@/routes/my-page/components/OnsCampaignEmojiInstruction'
import userService from '@/services/user'

export default {
    name: 'GoodbyeOnsCampaignPage',
    data: () => ({
        page: 0,
        dictation: '',
    }),
    components: {
        OnsCampaignEmojiInstruction,
    },
    created() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'CampaignPage_Enter',
            },
        })
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        profile() {
            return this.me.profile
        },
        disableButtonFix() {
            return [1, 3].includes(this.page)
        },
        needTitle() {
            return [0, 1].includes(this.page)
        },
        title() {
            return require(`@/assets/images/my-page/GoodByeOnsCampaign/ons_campaign_${this.page}_title.png`)
        },
        wholeImg() {
            if (this.emojiInstructions) return false

            return require(`@/assets/images/my-page/GoodByeOnsCampaign/ons_campaign_${this.page}_desc.png`)
        },
        buttonLabel() {
            if (this.page === 0) return this.$translate('START_CAMPAIGN')
            else if (this.page === 4) return this.$translate('COMPLETE_CAMPAIGN')

            return this.$translate('NEXT')
        },
        styleVariationByPage() {
            return `style-${this.page}`
        },
        needInput() {
            return [1, 3].includes(this.page)
        },
        emojiInstructions() {
            return [0, 4].includes(this.page)
        },
        disabled() {
            if (this.needInput) {
                return this.dictation !== this.$translate(`CAMPAIGN_INPUT_SENTENCE_${this.page}`)
            }
            return false
        },
    },
    methods: {
        prev() {
            if (this.page === 0) this.$stackRouter.pop()
            else {
                this.page -= 1
            }
        },
        onClickBottomBtn() {
            if (this.profile.no_ons_campaign !== 0) {
                const status = this.profile.no_ons_campaign
                if (status === 1) this.$toast.success(this.$translate('CAMPAIGN_ALREADY_PARTICIPATED'))
                if (status === -1) this.$toast.error(this.$translate('CAMPAIGN_BANNED_USER'))

                this.$stackRouter.pop()
                return
            }
            this.dictation = ''

            if (this.page !== 4) {
                this.page += 1
            } else {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'CampaignPage_Participate_Finish',
                    },
                })
                userService
                    .participateCampaign()
                    .then(res => {
                        this.$toast.success(res.msg)
                        this.$store.commit('setMe', { ...this.me, profile: { ...this.profile, no_ons_campaign: 1 } })
                        this.$stackRouter.pop()
                    })
                    .catch(e => {
                        this.$toast.error(`오류가 발생했어요. 플러스친구로 문의주세요!`)
                        this.$stackRouter.pop()
                    })
            }
        },
    },
}
</script>

<style scoped lang="scss">
.ons-campaign-container {
    //padding-bottom: 200px;
}
.title {
    max-width: 280px;
    margin: 32px auto;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        font-size: 32px;
        text-align: center;
    }

    img {
        height: 90px;

        &.style-1 {
            height: 30px;
        }
    }
}
.content {
    .desc {
        text-align: center;
        font-size: 18px;
        line-height: 24px;
        color: black;
        margin-top: 24px;
        margin-bottom: 34px;

        &.style-0 {
            font-size: 14px;
        }
        &.style-1 {
            font-size: 16px;
        }
        &.style-2 {
            line-height: 28px;
            margin-bottom: 44px;
        }
        &.style-3 {
            line-height: 28px;
            margin-bottom: 44px;
        }
        &.style-4 {
            line-height: 28px;
        }
    }
    .whole-img-container {
        margin: 0 auto 32px auto;

        &.style-1 {
            max-width: calc(100vw - 40px);
        }
        &.style-2 {
            max-width: calc(100vw - 96px);
        }
        &.style-3 {
            max-width: calc(100vw - 108px);
        }

        img {
            width: 100%;
            height: auto;
        }
    }
    .input-container {
        padding: 16px;
        color: black;
        margin-bottom: 50px;
        text-align: center;

        ::v-deep .textarea-with-x {
            display: flex;
            align-items: center;
            .material-icons {
                display: none;
            }
            &.focus {
                border: 1px solid $blue-facebook !important;
            }
        }
    }
}
::v-deep .bottom-button {
    &.disableButtonFix {
        position: static;
        border: none;
    }
}
</style>
